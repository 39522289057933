import axios from 'axios'
const CryptoJS = require("crypto-js");

const API_URL = process.env.REACT_APP_API_URL
const API_SALT = process.env.REACT_APP_API_SALT
const API_TOKEN = process.env.REACT_APP_API_TOKEN

axios.defaults.headers.common['Authorization'] = 'Bearer ' + API_TOKEN;
axios.defaults.headers.common['Content-Type'] = 'application/json';


export const createSurvey = async (i_customer, survey_id, survey_answers) => {

    let checksum = CryptoJS.MD5(API_SALT + survey_id + i_customer).toString()
    console.log({i_customer, survey_id, survey_answers, checksum})
    let {data} = await axios.post(`${API_URL}/api/v1/customer/survey/notification`, {
        i_customer: i_customer,
        hash: checksum,
        survey_id: survey_id,
        responses: survey_answers
    } )
    
    return data
}







